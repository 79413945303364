import React from "react";

const OosVille = () => {
  return (
    <div className='max-w-7xl mx-auto items-center grid grid-cols-1 lg:grid-cols-2 gap-4 py-12'>
      <div className='relative'>
        <div className='w-[100%] h-[100%]'>
          <img src={"/images/oosville.webp"} />
        </div>
        {/* gradient start */}
        {/* <div className='absolute z-[3] -left-1/2 top-0 w-[50%] h-[50%] rounded-full white__gradient' /> */}
        {/* <div className='absolute z-[0] w-[50%] h-[50%] -left-1/2 bottom-0 rounded-full pink__gradient' /> */}
        {/* gradient end */}
      </div>
      <div className='text-gray-200'>
        <h2
          className={`font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full text-gradient`}
        >
          OO’SVille
        </h2>
        <p className='font-normal text-gray-300 text-[18px] leading-[30.8px]'>
          This is a world with fantastic creatures that has only been discovered
          and seen by one person – the famous and eccentric Dr. Loo. In order to
          keep the 25 unique characters from being found, Dr. Loo has destroyed
          all maps and evidence of their existence. But the doctor has also
          disappeared to this secret world.
        </p>
        <p className='font-normal text-gray-300 text-[18px] leading-[30.8px]'>
          Each character in the NFT collection has its own fantastic personality
          and backstory. To enter Oo’sville, you need to purchase one of these
          unique characters. This mythical place is only open to the bravest and
          most daring of individuals. Will you follow the clues left to Shin,
          Dr. Loo’s brainchild and only confidant, and discover what lies at the
          end of the path? Be the exclusive few who get to enter a world like no
          other.
        </p>
      </div>
    </div>
  );
};

export default OosVille;
