import React, { useEffect, useState } from "react";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import Loader from "../components/Loader";

import oneE from "../assets/rating/01-E.png";
import twoE from "../assets/rating/02-E.png";
import threeE from "../assets/rating/03-E.png";
import fourE from "../assets/rating/04-E.png";
import fiveE from "../assets/rating/05-E.png";
import one from "../assets/rating/01-F.png";
import two from "../assets/rating/02-F.png";
import three from "../assets/rating/03-F.png";
import four from "../assets/rating/04-F.png";
import five from "../assets/rating/05-F.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { toast } from "react-hot-toast";
import CommentCard from "../components/CommentCard";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CharacterPage = () => {
  let { id } = useParams();
  const tabs = [{ name: "Character Description" }, { name: "Comments" }];
  const [selectedTab, setSelectedTab] = useState(tabs[0].name);
  const [character, setCharacter] = useState(null);
  const [rating, setRating] = useState(null);
  const [userRating, setUserRating] = useState(null);
  const [hasUserRated, setHasUserRated] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [comment, setComment] = useState("");
  const [textAreaActive, setTextAreaActive] = useState(false);

  const q = query(collection(db, "comments"), where("characterId", "==", id));

  const [values, commentsLoading, commentsError, snapshot] = useCollectionData(
    q,
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const getCharacter = async () => {
    const q = query(
      collection(db, "Characters"),
      where("documentId", "==", id)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if (doc.data()) {
        setCharacter(doc.data());
      }
    });
  };
  useEffect(() => {
    getCharacter();
    if (user) {
      getUserRating();
    }
  }, [user]);

  const getUserRating = async () => {
    const ratingsRef = collection(db, "ratings");
    const q = query(
      ratingsRef,
      where("characterId", "==", id),
      where("userId", "==", user.uid)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.data()) {
        setHasUserRated(true);
        setRating(doc.data().rating);
        setUserRating(doc.data().rating);
      }
    });
  };

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const addRating = async () => {
    const docId = `${id}_${auth.currentUser.uid}`;
    const data = {
      characterId: id,
      userId: auth.currentUser.uid,
      rating,
    };
    await setDoc(doc(db, "ratings", docId), data);
    getUserRating();
  };

  const addComment = async (e) => {
    e.preventDefault();
    const docRef = await addDoc(collection(db, "comments"), {
      userId: user.uid,
      avatar: user.photoURL,
      displayName: user.displayName,
      characterId: id,
      comment: comment,
      createdAt: Timestamp.fromDate(new Date(Date.now())),
    });
    await updateDoc(docRef, {
      documentId: docRef.id,
    });
    toggleTextArea();
    setComment("");
  };

  const toggleTextArea = () => {
    setTextAreaActive(!textAreaActive);
  };

  return (
    <div className='mx-auto max-w-7xl min-h-screen overflow-hidden text-white'>
      {character ? (
        <div>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
            <div className='w-full lg:col-span-1 space-y-8'>
              <img
                className='rounded-md bg-gray-700'
                src={character.characterImage[0].downloadURL}
                alt={character.characterName}
              />
              <div ma>
                <div className='sm:hidden'>
                  <label htmlFor='tabs' className='sr-only'>
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id='tabs'
                    name='tabs'
                    className='p-1 block w-full bg-gray-800 text-gray-200 border-gray-300'
                    value={selectedTab}
                    onChange={(e) => setSelectedTab(e.target.value)}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className='hidden sm:block'>
                  <nav
                    className='isolate flex divide-x divide-gray-800 shadow'
                    aria-label='Tabs'
                  >
                    {tabs.map((tab) => (
                      <div
                        key={tab.name}
                        onClick={() => setSelectedTab(tab.name)}
                        className={classNames(
                          tab.name === selectedTab
                            ? "text-gray-100 bg-gray-800"
                            : "text-gray-300 hover:text-gray-200",
                          "group relative min-w-0 flex-1 overflow-hidden bg-gray-700 cursor-pointer py-4 px-4 text-sm font-medium text-center hover:bg-gray-800 focus:z-10"
                        )}
                      >
                        <span>{tab.name}</span>
                        <span
                          aria-hidden='true'
                          className={classNames(
                            tab.name === selectedTab
                              ? "bg-green-700"
                              : "bg-transparent",
                            "absolute inset-x-0 bottom-0 h-0.5"
                          )}
                        />
                      </div>
                    ))}
                  </nav>
                </div>
              </div>
            </div>

            <div className='lg:col-span-2'>
              <h3 className='text-gradient text-xl font-semibold mb-3'>
                {character.characterName}
              </h3>
              {user ? (
                <div
                  className='flex space-x-2 my-4'
                  onMouseLeave={() => setRating(hasUserRated ? userRating : 0)}
                >
                  <img
                    src={rating >= 1 ? one : oneE}
                    alt=''
                    className='h-8 cursor-pointer'
                    onMouseEnter={() => setRating(1)}
                    onMouseLeave={() => setRating(0)}
                    onClick={addRating}
                  />
                  <img
                    src={rating >= 2 ? two : twoE}
                    alt=''
                    className='h-8 cursor-pointer'
                    onMouseEnter={() => setRating(2)}
                    onMouseLeave={() => setRating(1)}
                    onClick={addRating}
                  />
                  <img
                    src={rating >= 3 ? three : threeE}
                    alt=''
                    className='h-8 cursor-pointer'
                    onMouseEnter={() => setRating(3)}
                    onMouseLeave={() => setRating(2)}
                    onClick={addRating}
                  />
                  <img
                    src={rating >= 4 ? four : fourE}
                    alt=''
                    className='h-8 cursor-pointer'
                    onMouseEnter={() => setRating(4)}
                    onMouseLeave={() => setRating(3)}
                    onClick={addRating}
                  />
                  <img
                    src={rating >= 5 ? five : fiveE}
                    alt=''
                    className='h-8 cursor-pointer'
                    onMouseEnter={() => setRating(5)}
                    onMouseLeave={() => setRating(4)}
                    onClick={addRating}
                  />
                </div>
              ) : (
                <div className='my-4'>
                  <div className='flex space-x-3'>
                    {[oneE, twoE, threeE, fourE, fiveE].map((img) => (
                      <img src={img} alt='' className='h-8 cursor-pointer' />
                    ))}
                  </div>
                  <p className='text-md text-gray-500 my-5'>
                    Sign In to add a comment and give rating
                  </p>
                </div>
              )}
              {selectedTab === "Character Description" ? (
                <div className='text-gray-300 text-sm tracking-wide leading-relaxed'>
                  {parse(character.characterDescription, options)}
                </div>
              ) : (
                <div>
                  {user && (
                    <div>
                      <button
                        onClick={toggleTextArea}
                        className='mb-2 inline-flex items-center bg-indigo-600 px-4 py-1 text-xs font-medium text-white shadow-sm hover:bg-indigo-700'
                      >
                        {textAreaActive
                          ? "Close Comment Panel"
                          : "Add your comment"}
                      </button>
                      {textAreaActive && (
                        <div className='flex items-start space-x-4'>
                          <div className='min-w-0 flex-1'>
                            <form onSubmit={addComment} className='relative'>
                              <div className='overflow-hidden border border-gray-900 shadow-sm'>
                                <label htmlFor='comment' className='sr-only'>
                                  Add your comment
                                </label>
                                <textarea
                                  rows={3}
                                  name='comment'
                                  id='comment'
                                  className='bg-gray-800 block w-full resize-none border-0 p-3 focus:ring-0 sm:text-sm'
                                  placeholder='Add your comment...'
                                  value={comment}
                                  onChange={(e) => setComment(e.target.value)}
                                />

                                {/* Spacer element to match the height of the toolbar */}
                                <div className='py-2' aria-hidden='true'>
                                  {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                  <div className='py-px'>
                                    <div className='h-9' />
                                  </div>
                                </div>
                              </div>

                              <div className='absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2'>
                                <div className='flex-shrink-0'>
                                  <button
                                    type='submit'
                                    disabled={comment.length < 1}
                                    className='inline-flex items-center bg-indigo-600 px-6 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 disabled:bg-gray-600'
                                  >
                                    Post a Comment
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className='my-4 flex flex-col space-y-4'>
                    {values.map((doc) => (
                      <CommentCard key={doc.documentId} document={doc} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CharacterPage;
