import React from "react";

const DrLoo = () => {
  return (
    <div className='max-w-7xl mx-auto  grid grid-cols-1 lg:grid-cols-2 gap-4 py-12'>
      <div className='w-full'>
        <img src={"/images/dr-loo.webp"} />
      </div>
      <div className='text-gray-200'>
        <h3 className='font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full text-gradient'>
          DR. LOO
        </h3>
        <p className='font-normal text-gray-300 text-[18px] leading-[30.8px]'>
          A researcher who has dedicated his whole life to scientific discovery,
          Dr. Loo heard about a group of exotic creatures hidden away where no
          one has ever seen them. For years he tries to find them, until one day
          he accidentally comes across research that proves that the legends of
          these creatures are rooted in reality. Leaving the safety of his lab
          behind, he risks all of his finances to find out if the mythical
          creatures are real.
        </p>
        <p className='font-normal text-gray-300 text-[18px] leading-[30.8px]'>
          Delving into dusty books and old maps has led Dr. Loo’s scientific
          community into thinking that his fantasies have made him mad. But he
          will stop at nothing until he finds what he knows is real. Shin, his
          only friend and confidant, is the one person he reaches out to when he
          finally finds what he’s been looking for…25 unique creatures unlike
          anything he’s ever come across.
        </p>
        <p className='font-normal text-gray-300 text-[18px] leading-[30.8px]'>
          Now that he’s discovered them, Dr. Loo knows that the creatures will
          not be safe if found by anyone else in his community. He burns all of
          the papers and maps that he used to track them down, and leaves them
          in the safety of Oo’sville, the world he both found them in, and
          fortified to protect them.
        </p>
        <p className='font-normal text-gray-300 text-[18px] leading-[30.8px]'>
          Only those he deems worthy can enter Oo’sville to marvel at the rare
          and stunning creatures that he has found. Are you brave, are you
          daring, are you worthy?
        </p>
      </div>
    </div>
  );
};

export default DrLoo;
