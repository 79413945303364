import React from "react";

const Hero = () => {
  return (
    <div className='max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 py-12 items-center'>
      <div className={`lg:hidden flex-1 flex md:my-0 my-5 relative`}>
        <div className='w-[100%] h-[100%] relative z-[5]'>
          <img src={"/images/hero.webp"} />
        </div>
        {/* gradient start */}
        {/* <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient' />
        <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 pink__gradient' /> */}
        {/* gradient end */}
      </div>
      <div className='text-gray-200'>
        <h1 className='flex-1 font-semibold sm:text-[72px] text-[52px] text-gradient ss:leading-[100.8px] leading-[75px]'>
          SHINTOON
        </h1>
        <p className='font-normal text-gray-300 text-[18px] leading-[30.8px]'>
          Shintoon, presenting it's Unique 25 Characters.
        </p>
        <p className='font-normal text-gray-300 text-[18px] leading-[30.8px]'>
          Out of the 25 exciting characters we were able to design 1000 NFTs as
          part of Oo'sville collection. Each one unique so that you will be the
          only person that owns the one you decide to buy.
        </p>
      </div>
      <div className={`hidden flex-1 lg:flex md:my-0 my-5 relative`}>
        <div className='w-[100%] h-[100%] relative z-[5]'>
          <img src={"/images/hero.webp"} />
        </div>
        {/* gradient start */}
        {/* <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient' />
        <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 pink__gradient' /> */}
        {/* gradient end */}
      </div>
    </div>
  );
};

export default Hero;
