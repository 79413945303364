"use client";
import { useState } from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const CharacterSlide = ({
  characterName,
  characterDescription,
  characterImage,
  documentId,
}) => {
  return (
    <div className='mx-2 lg:px-12 items-center grid grid-cols-1 lg:grid-cols-2 gap-12'>
      <Link to={`/oosville/${documentId}`} className=' bg-gray-700 rounded-md'>
        <img
          className='w-full  object-cover'
          src={characterImage}
          alt={characterName}
        />
      </Link>
      <div className='text-gray-300 text-center'>
        <h3 className='text-gray-200 text-2xl'>{characterName}</h3>
      </div>
    </div>
  );
};

export default CharacterSlide;
