import React from "react";
import { auth, db } from "../firebase";
import { deleteDoc, doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import Moment from "react-moment";
import "moment-timezone";

const CommentCard = ({ document }) => {
  const [user] = useAuthState(auth);
  const [value, loading, error, snapshot] = useDocumentData(
    doc(db, "users", document.userId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const deleteComment = async (commentId) => {
    await deleteDoc(doc(db, "comments", commentId));
  };

  console.log(document.createdAt.seconds);

  return (
    <div
      className='flex item-start bg-gray-800 py-2 px-4 text-gray-200'
      key={document.documentId}
    >
      <div className='flex flex-1 gap-x-4'>
        {/* {!loading && (
          <img
            className='h-12 w-12 flex-none rounded-full bg-gray-50'
            src={value.avatar ? value.avatar : document.avatar}
            alt=''
          />
        )} */}
        <div className='min-w-0 flex-auto'>
          {!loading && (
            <p className='text-sm font-semibold leading-6'>
              {value.displayName}
            </p>
          )}
          <p className='text-xs font-light text-gray-200'>
            <Moment format='DD MMM YY'>
              {new Date(document.createdAt.seconds * 1000)}
            </Moment>
          </p>
          <p className='mt-1 text-sm leading-5 text-gray-300'>
            {document.comment}
          </p>
        </div>
      </div>
      {user && user.uid === document.userId && (
        <button onClick={() => deleteComment(document.documentId)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='#fff'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default CommentCard;
