import React, { useState } from "react";

const FAQ = () => {
  const [box1, setBox1] = useState(false);
  const [box2, setBox2] = useState(false);
  const [box3, setBox3] = useState(false);
  const [box4, setBox4] = useState(false);

  return (
    <div className='w-full'>
      <div className='relative flex flex-col items-center justify-center sm:px-0 px-6 z-20 pb-32'>
        <div className='md:py-18 py-12'>
          <h1 className='font-poppins md:text-5xl text-xl font-bold leading-10 text-gradient'>
            Frequently asked questions
          </h1>
        </div>
        <div className='lg:w-1/2 md:w-8/12 sm:w-9/12 w-full'>
          <div className='bg-black-gradient shadow rounded p-4'>
            <div className='flex items-center justify-between'>
              <div>
                <h2 className='font-poppins text-base font-semibold leading-none text-gray-100'>
                  What is OO’SVille?
                </h2>
              </div>
              <button
                onClick={() => setBox1(!box1)}
                className='ring-offset-white cursor-pointer'
              >
                {box1 ? (
                  <svg
                    role='button'
                    aria-label='close dropdown'
                    width='10'
                    height='6'
                    viewBox='0 0 10 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1 5L5 1L9 5'
                      stroke='#FFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                ) : (
                  <svg
                    width='10'
                    role='button'
                    aria-label='open dropdown'
                    height='6'
                    viewBox='0 0 10 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1 1L5 5L9 1'
                      stroke='#FFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                )}
              </button>
            </div>

            {box1 && (
              <ul className=''>
                <li>
                  <p className='font-poppins text-base leading-normal text-gray-200 mt-4 lg:w-96'>
                    OO’SVille is the name of our NFT collection as well as the
                    game we are creating for the buyers.
                  </p>
                </li>
              </ul>
            )}
          </div>
          <div className='bg-black-gradient shadow rounded p-4 mt-8'>
            <div className='flex items-center justify-between'>
              <div>
                <h2 className='font-poppins text-base font-semibold leading-none text-gray-100'>
                  How Many OO’SVille NFTs are there?
                </h2>
              </div>
              <button
                onClick={() => {
                  setBox2(!box2);
                }}
                data-menu
                className='ring-offset-white cursor-pointer'
              >
                {box2 ? (
                  <svg
                    role='button'
                    aria-label='close dropdown'
                    width='10'
                    height='6'
                    viewBox='0 0 10 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1 5L5 1L9 5'
                      stroke='#FFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                ) : (
                  <svg
                    width='10'
                    role='button'
                    aria-label='open dropdown'
                    height='6'
                    viewBox='0 0 10 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1 1L5 5L9 1'
                      stroke='#FFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                )}
              </button>
            </div>
            {box2 && (
              <ul>
                <li>
                  <p className='font-poppins text-base leading-normal text-gray-200 mt-4 lg:w-96'>
                    Out of the 25 exciting characters we were able to design
                    1000 NFTs as part of this collection. Each one unique so
                    that you will be the only person that owns the one you
                    decide to buy.
                  </p>
                </li>
              </ul>
            )}
          </div>
          <div className='bg-black-gradient shadow rounded p-4 mt-8'>
            <div className='flex items-center justify-between'>
              <div>
                <h2 className='font-poppins text-base font-semibold leading-none text-gray-100'>
                  What can I do with OO’SVille NFTs?
                </h2>
              </div>
              <button
                onClick={() => {
                  setBox3(!box3);
                }}
                data-menu
                className='ring-offset-white cursor-pointer'
              >
                {box3 ? (
                  <svg
                    role='button'
                    aria-label='close dropdown'
                    width='10'
                    height='6'
                    viewBox='0 0 10 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1 5L5 1L9 5'
                      stroke='#FFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                ) : (
                  <svg
                    width='10'
                    role='button'
                    aria-label='open dropdown'
                    height='6'
                    viewBox='0 0 10 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1 1L5 5L9 1'
                      stroke='#FFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                )}
              </button>
            </div>
            {box3 && (
              <ul>
                <li>
                  <p className='font-poppins text-base leading-normal text-gray-200 mt-4 lg:w-96'>
                    Besides the typical business side of NFTs ours have a
                    special purpose that is directly related to our game. Once
                    you finish purchasing a limited edition NFT you can play a
                    special game that we have designed on our website. In it you
                    will be able to play with your actual character and the more
                    you play the more score you can earn. Eventually you can
                    purchase our Token and get started trading.
                  </p>
                </li>
              </ul>
            )}
          </div>
          <div className='bg-black-gradient shadow rounded p-4 mt-8'>
            <div className='flex items-center justify-between'>
              <div>
                <h2 className='font-poppins text-base font-semibold leading-none text-gray-100'>
                  Who is behind the OO’SVille collection?
                </h2>
              </div>
              <button
                onClick={() => setBox4(!box4)}
                data-menu
                className='ring-offset-white cursor-pointer'
              >
                {box4 ? (
                  <svg
                    role='button'
                    aria-label='close dropdown'
                    width='10'
                    height='6'
                    viewBox='0 0 10 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1 5L5 1L9 5'
                      stroke='#FFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                ) : (
                  <svg
                    width='10'
                    role='button'
                    aria-label='open dropdown'
                    height='6'
                    viewBox='0 0 10 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1 1L5 5L9 1'
                      stroke='#FFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                )}
              </button>
            </div>
            {box4 && (
              <ul>
                <li>
                  <p className='font-poppins text-base leading-normal text-gray-200 mt-4 lg:w-96'>
                    From 2018 until present day the small team of Sina and Shima
                    have been working endlessly to make this project a reality.
                  </p>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
