import { useScrollSection } from "react-scroll-section";
import { useAuthState, useSignInWithGoogle } from "react-firebase-hooks/auth";
import { toast } from "react-hot-toast";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const roadMapSection = useScrollSection("roadmap");
  const [signInWithGoogle] = useSignInWithGoogle(auth);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleSignIn = () => {
    signInWithGoogle()
      .then(({ user }) => {
        setDoc(doc(db, "users", user.uid), {
          email: user.email,
          displayName: user.displayName,
          avatar: user.photoURL,
          uid: user.uid,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLogout = async () => {
    signOut(auth)
      .then(() => {
        toast.success("Logged out successfully.");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <nav className='max-w-7xl w-full mx-auto flex py-6 justify-between items-center navbar'>
      <Link to='/'>
        <img src='/images/logo.png' alt='Shintoon' className='h-[35px]' />
      </Link>
      <div className='flex justify-between items-center gap-x-8'>
        <Link to='/oosville'>
          <img src='/images/ooslogo.png' className='h-[35px]' alt='oosville' />
        </Link>
        {user ? (
          <div className='flex gap-x-4 items-center'>
            <button
              onClick={handleLogout}
              className='bg-blue-gradient rounded-[8px] px-4 py-1.5 md:px-6 md:py-2'
            >
              Logout
            </button>
          </div>
        ) : (
          <div>
            <button
              onClick={handleSignIn}
              className='bg-blue-gradient rounded-[8px] px-4 py-1.5 md:px-6 md:py-2'
            >
              Log in
            </button>
          </div>
        )}
      </div>

      {/* <div className='sm:hidden flex flex-1 justify-end items-center z-10'>
        <button onClick={() => setToggle(!toggle)}>
          {!toggle ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='#a2a2a2'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
              />
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='#a2a2a2'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          )}
        </button>

        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-gray-900 absolute top-20 right-0 left-0 my-2 w-full sidebar`}
        >
          <ul className='list-none flex justify-end items-start flex-1 flex-col'>
            <li className='font-medium cursor-pointer text-[16px] text-gray-300 mb-4'>
              <Link to='/oosville'>OO'sVille</Link>
            </li>

            {pathname === "/" && (
              <li
                onClick={roadMapSection.onClick}
                className='font-medium cursor-pointer text-[16px] text-gray-300 mb-4'
              >
                <p>Roadmap</p>
              </li>
            )}
            {user ? (
              <button
                type='button'
                onClick={handleLogout}
                className={`py-1 px-2 font-medium text-[12px] rounded-[8px] text-primary bg-blue-gradient outline-none `}
              >
                Sign Out
              </button>
            ) : (
              <button
                type='button'
                onClick={handleSignIn}
                className={`py-1 px-2 font-medium text-[12px] rounded-[8px] text-primary bg-blue-gradient outline-none `}
              >
                Sign In
              </button>
            )}
          </ul>
        </div>
      </div> */}
    </nav>
  );
};

export default Navbar;
