import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
import App from "./App";
import { Toaster } from "react-hot-toast";
import { ScrollingProvider } from "react-scroll-section";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Toaster />
    <ScrollingProvider>
      <App />
    </ScrollingProvider>
  </BrowserRouter>
);
