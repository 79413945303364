import React from "react";
import { Route, Routes } from "react-router-dom";

import CharacterPage from "./pages/CharacterPage";
import CharactersPage from "./pages/CharactersPage";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import AdminPage from "./pages/AdminPage";

const App = () => {
  return (
    <div className='mx-4 md:mx-16 lg:mx-0'>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route path='/admin' element={<AdminPage />} />
        <Route exact path='/oosville' element={<CharactersPage />} />
        <Route exact path='/oosville/:id' element={<CharacterPage />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
