import {
  Pic01,
  Pic02,
  Pic03,
  Pic04,
  Pic05,
  Pic06,
  Pic07,
  Pic08,
  Pic09,
  Pic10,
  Pic11,
  Pic12,
  Pic13,
  Pic14,
  Pic15,
  Pic16,
  Pic17,
  Pic18,
  Pic19,
  Pic20,
  Pic21,
  Pic22,
  Pic23,
  Pic24,
  Pic25,
  Pic26,
  Pic27,
  Pic28,
  Pic29,
  Pic30,
  Pic31,
  Pic32,
  Pic33,
  Pic34,
  Pic35,
  Pic36,
  Pic37,
  Pic38,
  Pic39,
  Pic40,
  Pic41,
  Pic42,
  Pic43,
} from "../assets/characters";
import React from "react";
import Marquee from "react-fast-marquee";
import uuid from "react-uuid";

const CustomMarquee = () => {
  const characterImages = [
    Pic01,
    Pic02,
    Pic03,
    Pic04,
    Pic05,
    Pic06,
    Pic07,
    Pic08,
    Pic09,
    Pic10,
    Pic11,
    Pic12,
    Pic13,
    Pic14,
    Pic15,
    Pic16,
    Pic17,
    Pic18,
    Pic19,
    Pic20,
    Pic21,
    Pic22,
    Pic23,
    Pic24,
    Pic25,
    Pic26,
    Pic27,
    Pic28,
    Pic29,
    Pic30,
    Pic31,
    Pic32,
    Pic33,
    Pic34,
    Pic35,
    Pic36,
    Pic37,
    Pic38,
    Pic39,
    Pic40,
    Pic41,
    Pic42,
    Pic43,
  ];

  const result = new Array(Math.ceil(characterImages.length / 12))
    .fill()
    .map((_) => characterImages.splice(0, 12));

  return (
    <div>
      <Marquee gradient={false} speed={35} pauseOnClick pauseOnHover>
        {result[0].map((el) => (
          <div className='rounded-2xl md:rounded-3xl overflow-hidden m-2 md:m-5'>
            <img
              alt={el}
              key={uuid()}
              className='w-28 md:w-48 lg:w-64'
              src={el}
            />
          </div>
        ))}
      </Marquee>
      <Marquee
        gradient={false}
        speed={35}
        pauseOnClick
        pauseOnHover
        direction='right'
      >
        {result[1].map((el) => (
          <div className='rounded-xl md:rounded-3xl overflow-hidden m-2 md:m-5'>
            <img
              alt={el}
              key={uuid()}
              className='w-28 md:w-48 lg:w-64'
              src={el}
            />
          </div>
        ))}
      </Marquee>
      <Marquee gradient={false} speed={35} pauseOnClick pauseOnHover>
        {result[2].map((el) => (
          <div className='rounded-2xl md:rounded-3xl overflow-hidden m-2 md:m-5'>
            <img
              alt={el}
              key={uuid()}
              className='w-28 md:w-48 lg:w-64'
              src={el}
            />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default CustomMarquee;
