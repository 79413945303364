"use client";
import { Section } from "react-scroll-section";

const features = [
  {
    id: "feature-1",
    icon: "/images/roadmap/icon1.png",
    title: "Chapter 1",
    content: [
      "The first chapter consists of 40 sets of Oo’s, in each set there are characters from creatures discovered by Dr. Lo from ancient legends.",
    ],
  },
  {
    id: "feature-2",
    icon: "/images/roadmap/icon2.png",
    title: "Chapter 2",
    content: [
      "After selling at least 75% of the 39 sets of the first oo’sville chapter , we will upload the Island Guide map as the last set of the first chapter, which is the gateway to the island (chapter 2).",
      "In Oo’sville, you will encounter a different world, you will get to know the real characters of each creature in the collection, and you will see the results of Dr. Loo’s research. This is just the beginning of the story, strange things happen on the oo’sville..",
    ],
  },
  {
    id: "feature-3",
    icon: "/images/roadmap/icon3.png",
    title: "Chapter 3",
    content: [
      "Warning : The story is very likely to be leaked, so unfortunately we can't say anything about chapter 3.",
    ],
  },
];

const FeatureCard = ({ icon, title, content }) => (
  <div className='flex flex-row p-4 rounded-[20px] feature-card'>
    <div
      className={`w-[64px] h-[64px] rounded-full flex justify-center items-center bg-blue-200`}
    >
      <img
        src={icon}
        alt='icon'
        className='w-[80%] h-[80%] object-contain'
      />
    </div>
    <div className='flex-1 flex flex-col ml-12'>
      <h4 className='font-semibold text-white text-[18px] leading-[23.4px] mb-1'>
        {title}
      </h4>
      <ul className='list-disc list-inside'>
        {content.map((item) => (
          <li
            key={item}
            className='font-normal text-gray-300 text-[16px] leading-[24px]'
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const Roadmap = () => (
  <Section
    id='roadmap'
    className='max-w-7xl mx-auto sm:py-16 py-6 flex flex-col'
  >
    <div className='flex-1 flex justify-center items-center md:ml-10 ml-0 md:mt-0 mt-10 relative flex-col'>
      <div className='flex flex-row items-center py-4 pl-4 pr-12 bg-discount-gradient rounded-[10px] mb-2'>
        <div
          className={`w-[48px] h-[48px] rounded-full flex justify-center items-center bg-dimBlue`}
        >
          <svg
            className='w-[32px] h-[32px]'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='cyan'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941'
            />
          </svg>
        </div>

        <p className='font-normal leading-[30.8px] text-white ml-2 text-3xl'>
          ROADMAP
        </p>
      </div>
    </div>

    <div className='grid grid-cols-1 md:grid-cols-2 my-12 gap-4'>
      {features.map((feature) => (
        <FeatureCard key={feature.id} {...feature} />
      ))}
    </div>
  </Section>
);

export default Roadmap;
