import React from "react";

const Shin = () => {
  return (
    <div className='max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 py-12'>
      <div className='block lg:hidden w-full'>
        <img src={"/images/shin.webp"} />
      </div>
      <div className='text-gray-200'>
        <h3 className='font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full text-gradient'>
          Shin
        </h3>
        <p className='font-normal text-gray-300 text-[18px] leading-[30.8px]'>
          Shin is the only person who Dr. Loo has intrusted with the location of
          Oo’sville. She is also the only person that saw the maps before Dr.
          Loo destroyed them and, with a better memory, is the secret keeper of
          their hideout. Though Shin was also prominent in the same circles as
          Dr. Loo, she can no longer trust his friends either. She must use her
          wits and energy to ensure that the other researchers don’t find any
          clues to discover the truth.
        </p>
        <p className='font-normal text-gray-300 text-[18px] leading-[30.8px]'>
          When Dr. Loo doesn’t return from visiting Oo’sville, Shin must use her
          memory of the maps to follow the path that led Dr. Loo to discover the
          creatures. Though she trusts him, Shin hasn’t been to Oo’sville, so
          must set out to discover if the secret is real, or just a cleverly
          concocted fairy tale.
        </p>
      </div>
      <div className='hidden lg:block w-full'>
        <img src={"/images/shin.webp"} />
      </div>
    </div>
  );
};

export default Shin;
