import { Link } from "react-router-dom";

const socialMedia = [
  {
    id: "social-media-1",
    icon: "/images/social-media/instagram.png",
    link: "https://www.instagram.com/shintoon.eth/",
  },
  {
    id: "social-media-2",
    icon: "/images/social-media/discord.png",
    link: "https://discord.gg/EmVtc7NaKT",
  },
  {
    id: "social-media-3",
    icon: "/images/social-media/tiktok.png",
    link: "https://www.tiktok.com/@shintoondesign",
  },
  {
    id: "social-media-4",
    icon: "/images/social-media/twitter.png",
    link: "https://twitter.com/ShintoonNFT",
  },
  {
    id: "social-media-5",
    icon: "/images/social-media/threads.png",
    link: "https://www.threads.net/@shintoon.eth",
  },
];

const Footer = () => {
  return (
    <section className='flex flex-col py-8'>
      <div className='flex flex-col items-center justify-around space-y-8'>
        <p className='font-poppins text-center text-2xl text-gray-300 text-gradient'>
          info@shintoon.com
        </p>{" "}
        <div className='flex flex-row'>
          {socialMedia.map((social, index) => (
            <Link to={social.link} target='_blank' rel='noreferrer'>
              <img
                key={social.id}
                src={social.icon}
                alt={social.id}
                className={`w-12 h-12 object-contain cursor-pointer ${
                  index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
                }`}
              />
            </Link>
          ))}
        </div>
        <p className='font-medium text-center text-[16px] leading-[27px] text-gray-300'>
          Copyright Ⓒ 2023 Shintoon. All Rights Reserved.
        </p>
      </div>
    </section>
  );
};

export default Footer;
