import React from "react";
import Hero from "../components/Hero";
import OosVille from "../components/OosVille";
import Shin from "../components/Shin";
import DrLoo from "../components/DRLoo";
import CustomMarquee from "../components/ImageMarquee";
import Roadmap from "../components/Roadmap";
import FAQ from "../components/FAQ";
import Marquee from "react-fast-marquee";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <div className='w-full my-4'>
        <img src={"/images/all-01.png"} />
      </div>
      <OosVille />
      <div className='w-full my-4'>
        <img src={"/images/all-02.webp"} />
      </div>
      <DrLoo />
      <Shin />
      <div className='w-full my-4'>
        <img src={"/images/all-03.webp"} />
      </div>
      <CustomMarquee />
      <div className='w-full my-4'>
        <img src={"/images/all-04.png"} />
      </div>

      <Roadmap />
      <div className='w-full my-4'>
        <img src={"/images/all-05.webp"} />
      </div>
      <FAQ />
      <Marquee
        gradient={false}
        speed={50}
        pauseOnClick
        pauseOnHover
        direction='left'
      >
        <img className='h-64' src={"./images/anatomy.webp"} />
        <img className='h-64' src={"./images/anatomy.webp"} />
        <img className='h-64' src={"./images/anatomy.webp"} />
      </Marquee>
    </div>
  );
};

export default HomePage;
