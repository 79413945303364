"use client";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { collection } from "firebase/firestore";
import { db } from "../firebase";
import { Carousel } from "react-responsive-carousel";
import CharacterSlide from "../components/CharacterSlide";
import { useWindowWidth } from "@react-hook/window-size";
import Loader from "../components/Loader";

const CharactersPage = () => {
  const width = useWindowWidth();
  const [values, loading, error] = useCollectionDataOnce(
    collection(db, "Characters"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  const customRenderThumb = (children) =>
    children.map((item) => {
      return (
        <div className='my-2 lg:my-12 h-28 lg:h-44 bg-gray-700 rounded-md'>
          <img
            className='w-full h-full object-contain'
            src={item.props.characterImage}
            alt={item.props.characterName}
          />
        </div>
      );
    });
  const emptyThumbs = (children) => null;

  return (
    <div className='mx-auto max-w-7xl lg:px-6 py-12'>
      {error && (
        <strong className='text-white'>Error: {JSON.stringify(error)}</strong>
      )}
      {loading && (
        <span className='text-white'>
          <Loader />
        </span>
      )}
      {values && (
        <Carousel
          showStatus={false}
          showIndicators={false}
          renderItem={customRenderItem}
          thumbWidth={width > 768 ? 150 : 80}
          renderThumbs={customRenderThumb}
          showArrows={width > 768 ? true : false}
          infiniteLoop={true}
        >
          {[...values, ...values].map((item) => (
            <CharacterSlide
              key={item.documentId}
              characterName={item.characterName}
              characterDescription={item.characterDescription}
              characterImage={item.characterImage[0].downloadURL}
              documentId={item.documentId}
            />
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default CharactersPage;
